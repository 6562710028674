<template>
  <div class="advertisingManagement-view">
    <commonTable :tableData="tableData" :loading="loading" :paginationVisible="false">
      <template v-slot:table>
        <el-table-column prop="type" align="center" label="图片位置">
          <div class="operating" slot-scope="scope">
            <p>{{ scope.row.type == 1 ? '首页顶部' : scope.row.type == 2 ? '园区商城' : "" }}</p>
          </div>
        </el-table-column>

        <el-table-column prop="num" align="center" label="图片数量" />
        <el-table-column label="操作" align="center">
          <div class="operating" slot-scope="scope">
            <el-button size="mini" type="text" @click="edit_tap(scope.row)">编辑</el-button>
          </div>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
      :destroy-on-close="true" center @close="closeFun()" width="750px">
      <div>
        <el-row class="mb10 text-align-right">
          <el-button type="primary" @click="addCarousel">添加轮播图</el-button>
        </el-row>
        <el-row v-for="(row, index) in photoList" :key="index" class="bgf7fa pa24 mb10 carouselView"
          style="width:700px">
          <i class="el-icon-close carouselClose" @click="carouselClose(index)"></i>
          <div class="flex-a-c">
            <el-upload class="carouselUpload mr20" :action="$store.state.uploadingUrl" list-type="picture-card"
              :class="{ disabled: row.disabled }" :limit="1"
              :on-progress="linkmanImgProgress.bind(null, { 'index': index, 'row': row })"
              :on-success="linkmanImgSuccess.bind(null, { 'index': index, 'row': row })"
              :on-remove="linkmanImgRemove.bind(null, { 'index': index, 'row': row })" :file-list="row.photoImg">
              <i class="el-icon-plus"></i>
            </el-upload>
            <div>
              <div class="mb10">跳转至 <el-select class="w200" v-model="row.urlType" placeholder="请选择" clearable
                  @change="changeUrlType(index)">
                  <el-option v-for="(name, id) in photoUrl" :key="id" :label="name" :value="id">
                  </el-option>
                </el-select>
              </div>
              <div style="height:34px" class="mb10">
                <span class="mr10">{{ row.name }}</span>
                <el-button type="primary" v-if="row.urlType == 2" @click="showProductFun(index)">选择商品</el-button>
                <el-button type="primary" v-if="row.urlType == 1" @click="showActivityFun(index)">选择活动</el-button>
                <el-button type="primary" v-if="row.urlType == 3" @click="showArticleFun(index)">选择文章</el-button>
              </div>
              <div class="w340 d-flex align-items-center">
                <p>上传图片的最佳尺寸：350像素*200像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M</p>
              </div>
            </div>
          </div>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showModel = false">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="loadingBtn">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择商品" :visible.sync="showProduct"
      :destroy-on-close="true" width="80%" center>
      <commonTable :tableData="productTableData" :loading="productLoading" @handleSizeChange="productHandleSizeChange"
        @handleCurrentChange="productHandleCurrentChange" :currentPage="productCurrentPage" :total="productTotal">
        <template v-slot:table>
          <el-table-column width="55">
            <template slot-scope="scope">
              <el-radio class="ml10" v-model="productCheckedData.id" :label="scope.row.goodsId"
                @change="selectProduct(scope.row)">
                {{ ' ' }}
              </el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="goodPhoto" align="center" label="商品图片">
            <template slot-scope="scope">
              <el-image v-if="scope.row.goodPhoto" style="width: 100px; height: 80px;margin-bottom:-10px"
                :src="scope.row.goodPhoto.split(',')[0]" :preview-src-list="scope.row.goodPhoto.split(',')">
              </el-image>
              <p v-else style="width: 100px; height: 80px;line-height: 80px;">
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" align="center" label="商品名称" show-overflow-tooltip />
          <el-table-column prop="price" align="center" label="价格" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.price | getPrice }}
            </template>
          </el-table-column>
          <el-table-column prop="stockNum" align="center" label="库存" show-overflow-tooltip />
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkedProduct">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择活动" :visible.sync="showActivity"
      :destroy-on-close="true" width="80%" center>
      <commonTable :tableData="activityTableData" :loading="activityLoading"
        @handleSizeChange="activityHandleSizeChange" @handleCurrentChange="activityHandleCurrentChange"
        :currentPage="activityCurrentPage" :total="activityTotal">
        <template v-slot:table>
          <el-table-column width="55">
            <template slot-scope="scope">
              <el-radio class="ml10" v-model="activityCheckedData.id" :label="scope.row.aeId"
                @change="selectActivity(scope.row)">
                {{ ' ' }}
              </el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="photos" align="center" label="活动图片">
            <template slot-scope="scope">
              <el-image v-if="scope.row.photos" style="width: 100px; height: 80px;margin-bottom:-10px"
                :src="scope.row.photos"></el-image>
              <p v-else style="width: 100px; height: 80px;line-height: 80px;">
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="name" align="center" width="200" label="活动名称" show-overflow-tooltip />
          <el-table-column prop="description" width="250" show-overflow-tooltip align="center" label="活动概述" />
          <el-table-column prop="startTime" width="250" show-overflow-tooltip align="center" label="活动时间周期">
            <template slot-scope="scope">
              {{ scope.row.startTime + ' ~ ' + scope.row.endTime }}
            </template>
          </el-table-column>
          <el-table-column prop="phone" align="center" label="活动状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 1" type="info">未开始</el-tag>
              <el-tag v-else-if="scope.row.status == 2">进行中</el-tag>
              <el-tag v-else type="danger">已结束</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="userNum" align="center" label="报名人数限制" width="100">
            <template slot-scope="scope">
              {{ scope.row.userNum == 0 ? '不限制' : scope.row.userNum }}
            </template>
          </el-table-column>
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkedActivity">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择文章资讯" :visible.sync="showArticle"
      :destroy-on-close="true" width="80%" center>
      <commonTable :tableData="articleTableData" :loading="articleLoading" @handleSizeChange="articleHandleSizeChange"
        @handleCurrentChange="articleHandleCurrentChange" :currentPage="articleCurrentPage" :total="articleTotal">
        <template v-slot:table>
          <el-table-column width="55">
            <template slot-scope="scope">
              <el-radio class="ml10" v-model="articleCheckedData.id" :label="scope.row.messageId"
                @change="selectArticle(scope.row)">
                {{ ' ' }}
              </el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="photos" align="center" label="封面图" width="120px" required>
            <template slot-scope="scope">
              <el-image v-if="scope.row.photos" style="width: 100px; height: 100px;margin-bottom:-10px"
                :src="scope.row.photos">
              </el-image>
              <p v-else
                style="width: 100px; height: 100px;line-height: 100px;font-size:12px;margin:0 auto;background: #eee;">
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="title" align="center" label="标题" show-overflow-tooltip />
          <el-table-column prop="typeName" align="center" label="动态类型" show-overflow-tooltip />
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="checkedArticle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  fetchPhotoType,
  selectCompanyPhoto,
  insertCompanyPhoto,
  getByDictType,
  getGoodsListByLink,
  getParkMessage
} from "@/api/parkManagement";
import { activityEventList } from "@/api/companyManage";
import { getDataTimeSec } from "@/utils";
export default {
  name: "advertisement",
  components: {
    commonTable,
  },
  data() {
    return {
      tableData: [{
        type: 1,
        num: 0
      }, {
        type: 2,
        num: 0
      }],
      // tableData: [{
      //   type:3,
      //   num:0
      // }],
      loading: false,
      pageSize: 10,
      parkId: 0,
      //编辑配置
      loadingBtn: false,
      modelTitle: "",
      showModel: false,
      photoList: [{
        photoImg: [],//文件数组
        name: "",//内容名称
        photo: "",//轮播图路径
        urlType: "",//跳转类型
        parameter: "",//详情ID
        disabled: false,//是否隐藏添加上传图片组件
      }],
      photoUrl: {},
      formData: {
        photoDataList: [],
        type: 1,
      },
      //列表配置
      index: 0,
      //显示商品配置
      productCheckedData: {
        name: "",
        id: 0
      },
      productTableData: [],
      showProduct: false,//模态框显示状态
      productCurrentPage: 1, //当前页
      productPageSize: 10, //显示条数
      productLoading: false, //表格加载
      productTotal: 0, //总条数
      //活动列表配置
      activityCheckedData: {
        name: "",
        id: 0
      },
      activityTableData: [],
      showActivity: false,//模态框显示状态
      activityCurrentPage: 1, //当前页
      activityPageSize: 10, //显示条数
      activityLoading: false, //表格加载
      activityTotal: 0, //总条数
      //文章列表配置
      articleCheckedData: {
        name: "",
        id: 0
      },
      articleTableData: [],
      showArticle: false,//模态框显示状态
      articleCurrentPage: 1, //当前页
      articlePageSize: 10, //显示条数
      articleLoading: false, //表格加载
      articleTotal: 0, //总条数
    };
  },
  computed: {
    watchCheckedPark() {
      return this.$store.state.checkedPark
    },
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  watch: {
    watchCheckedPark(row) {
      this.parkId = row.parkId;
      this.currentPage = 1;
      this.productCurrentPage = 1;
      this.activityCurrentPage = 1;
      this.articleCurrentPage = 1;
      this.getPhoto();
      this.getProductList();//商品列表
      this.getActivityList();//活动列表
      this.getArticleList();//文章列表
    }
  },
  filters: {
    getPrice(price) {
      return Number(price / 100).toFixed(2) + "元"
    }
  },
  created() {
    let checkedPark = sessionStorage.getItem('checkedPark');
    this.parkId = JSON.parse(checkedPark).parkId;
    this.getPhoto()
    this.getByDictType();
    this.getProductList();//商品列表
    this.getActivityList();//活动列表
    this.getArticleList();//文章列表
  },
  methods: {
    /**@method 选择类型时清空选中内容 */
    changeUrlType(index) {
      this.productCheckedData = {
        name: "",
        id: 0
      };
      this.activityCheckedData = {
        name: "",
        id: 0
      };
      this.articleCheckedData = {
        name: "",
        id: 0
      };
      this.$set(this.photoList[index], "parameter", "")
      this.$set(this.photoList[index], "name", "")
    },
    /**文章配置 */
    /**@method 选中活动 */
    selectArticle(data) {
      this.articleCheckedData.name = data.title;
      this.articleCheckedData.id = data.dynamicId;
    },
    /**@method 确认选中活动 */
    checkedArticle() {
      this.$set(this.photoList[this.index], "parameter", "id=" + this.articleCheckedData.id + "&title=" + this.articleCheckedData.name)
      this.$set(this.photoList[this.index], "name", this.articleCheckedData.name)
      this.showArticle = false;
    },
    /**@method 显示社团活动列表 */
    showArticleModel() {
      this.showArticle = true;
      this.getArticleList();
    },
    /**@method 显示活动列表 */
    showArticleFun(index) {
      this.index = index;
      this.showArticle = true;
    },
    /**@method 活动获取列表 */
    async getArticleList() {
      let params = {
        pageSize: this.articlePageSize,
        pageNum: this.articleCurrentPage,
        parkId: this.parkId,
        isPage: 1
      };
      try {
        this.articleLoading = true;
        let res = await getParkMessage(params)
        this.articleLoading = false;

        const { data } = res;
        this.articleTableData = data.list;
        this.articleTotal = data.total;
      } catch (error) {
        this.articleLoading = false;
        this.articleTableData = [];
      }
    },
    /**@method 活动切换行 */
    articleHandleSizeChange(val) {
      this.articlePageSize = val;
      this.getArticleList();
    },
    /**@method 活动切换下一页 */
    articleHandleCurrentChange(val) {
      this.articleCurrentPage = val;
      this.getArticleList();
    },
    /**文章配置 */
    /**活动配置 */
    /**@method 选中活动 */
    selectActivity(data) {
      this.activityCheckedData.name = data.name;
      this.activityCheckedData.id = data.aeId;
    },
    /**@method 确认选中活动 */
    checkedActivity() {
      this.$set(this.photoList[this.index], "parameter", "id=" + this.activityCheckedData.id + "&title=" + this.activityCheckedData.name)
      this.$set(this.photoList[this.index], "name", this.activityCheckedData.name)
      this.showActivity = false;
    },
    /**@method 显示社团活动列表 */
    showActivityModel() {
      this.showActivity = true;
      this.getActivityList();
    },
    /**@method 显示活动列表 */
    showActivityFun(index) {
      this.index = index;
      this.showActivity = true;
    },
    /**@method 活动获取列表 */
    async getActivityList() {
      let params = {
        pageSize: this.activityPageSize,
        pageNum: this.activityCurrentPage,
        parkId: this.parkId
      };
      try {
        this.activityLoading = true;
        let res = await activityEventList(params)
        this.activityLoading = false;

        const { data } = res;
        this.activityTableData = data.pageInfo.list;
        this.activityTotal = data.pageInfo.total;
      } catch (error) {
        this.activityLoading = false;
        this.activityTableData = [];
      }
    },
    /**@method 活动切换行 */
    activityHandleSizeChange(val) {
      this.activityPageSize = val;
      this.getActivityList();
    },
    /**@method 活动切换下一页 */
    activityHandleCurrentChange(val) {
      this.activityCurrentPage = val;
      this.getActivityList();
    },
    /**活动配置 */
    /**商品配置 */
    /**@method 选中商品 */
    selectProduct(data) {
      this.productCheckedData.name = data.goodsName;
      this.productCheckedData.id = data.goodsId;
    },
    /**@method 确认选中商品 */
    checkedProduct() {
      this.$set(this.photoList[this.index], "parameter", "id=" + this.productCheckedData.id + "&title=" + this.productCheckedData.name)
      this.$set(this.photoList[this.index], "name", this.productCheckedData.name)
      this.showProduct = false;
    },
    /**@method 显示社团商品列表 */
    showProductModel() {
      this.showProduct = true;
      this.getProductList();
    },
    /**@method 显示商品列表 */
    showProductFun(index) {
      this.index = index;
      this.showProduct = true;
    },
    /**@method 商品获取列表 */
    async getProductList() {
      let params = {
        pageSize: this.productPageSize,
        pageNum: this.productCurrentPage,
        accountType: 9,//社团商品
        parkId: this.parkId,
        showAll: 2
      };
      try {
        this.productLoading = true;
        let res = await getGoodsListByLink(params)
        this.productLoading = false;

        const { data } = res;
        this.productTableData = data.pageInfo.list;
        this.productTotal = data.pageInfo.total;
      } catch (error) {
        this.productLoading = false;
        this.productTableData = [];
      }
    },
    /**@method 商品切换行 */
    productHandleSizeChange(val) {
      this.productPageSize = val;
      this.getProductList();
    },
    /**@method 商品切换下一页 */
    productHandleCurrentChange(val) {
      this.productCurrentPage = val;
      this.getProductList();
    },
    /**商品配置 */
    /**@method 添加轮播图 */
    addCarousel() {
      this.photoList.push({
        photoImg: [],
        name: "",
        photo: "",
        urlType: "",
        parameter: "",
        disabled: false,
      })
    },
    carouselClose(index) {
      this.photoList.splice(index, 1)
    },
    /**@method 获取字典数据 */
    getByDictType() {
      return getByDictType({ dictType: "photo_url", status: 0 }).then(res => {
        if (res.code === 200) {
          let photoUrl = {};
          for (let row of res.data.sysDictDataList) {
            photoUrl[row.dictValue] = row.dictLabel;
          }
          this.photoUrl = photoUrl;
        }
      })
    },
    /**@method 删除图片 */
    linkmanImgRemove(obj, file, fileList) {
      let index = obj.index;
      this.photoList[index].photo = "";
      this.photoList[index].disabled = false;
    },
    /**@method 图片上传 */
    linkmanImgProgress(obj, event, file, fileList) {
      let index = obj.index;
      this.photoList[index].disabled = true;
      this.$set(this.photoList, index, this.photoList[index])
    },
    /**@method 上传回调 */
    linkmanImgSuccess(obj, response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let index = obj.index;
      this.photoList[index].photo = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
      this.$set(this.photoList, index, this.photoList[index])
    },
    closeFun() {
      this.photoList = [{
        photoImg: [],//文件数组
        name: "",//内容名称
        photo: "",//轮播图路径
        urlType: "",//跳转类型
        parameter: "",//详情ID
        disabled: false,//是否隐藏添加上传图片组件
      }]
      this.productCheckedData = {
        name: "",
        id: 0
      };
      this.activityCheckedData = {
        name: "",
        id: 0
      };
      this.articleCheckedData = {
        name: "",
        id: 0
      };
    },
    /**@method 获取图片数量 */
    getPhoto() {
      this.loading = true
      fetchPhotoType({ ignore: true, parkId: this.parkId })
        .then(res => {
          this.loading = false
          let obj = {}
          res.data.map(res => {
            obj[res.type] = res.num
          })
          this.tableData.map(res => {
            res.num = obj[res.type] ? obj[res.type] : 0
          })
        })
        .catch(err => {
          this.loading = false
        })
    },
    /**@method 提交内容 */
    submitForm() {
      this.loadingBtn = true;
      let params = {
        ...this.formData,
        parkId: this.parkId
      };
      let photoList = [];
      for (let row of this.photoList) {
        photoList.push({
          photo: row.photo,
          urlType: row.urlType,
          parameter: row.parameter,
        });
      }
      params.photoDataList = photoList;
      insertCompanyPhoto(params).then(res => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "提交成功"
          });
          this.closeFun();//清空表单内容
          this.getPhoto();
          this.showModel = false;
        } else {
          this.$message.error(res.message)
        }
      }).catch(err => {
        if (err.code === 201) {
          this.$message.error(res.message)
        }
      })
      this.loadingBtn = false;
    },
    //编辑
    edit_tap(item) {
      this.showModel = true;
      this.formData.type = item.type;
      selectCompanyPhoto({
        type: item.type,
        parkId: this.parkId
      }).then(res => {
        if (res.data) {
          this.photoList = [];
          for (let row of res.data) {
            let value = {
              photoImg: [],//文件数组
              name: "",//内容名称
              photo: row.photo,//轮播图路径
              urlType: row.urlType,//跳转类型
              parameter: row.parameter,//详情ID
              disabled: false,//是否隐藏添加上传图片组件
            }
            if (row.parameter) {
              let params = row.parameter.split("&");
              if (params.length == 2) {
                value.name = params[1].split("=")[1];
              }
            }
            if (row.photo) {
              value.photoImg = [{ url: row.photo }];
              value.disabled = true;
            }
            this.photoList.push(value);
          }
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.carouselView {
  position: relative;

  .carouselClose {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    color: red;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s linear;
  }

  .carouselClose:hover {
    transform: scale(1.1);
  }
}

.advertisingManagement-view {
  background: #ffff;
  padding: 24px;
}

.operating {
  .el-button--text {
    color: #51CBCD;
  }
}
</style>